html,
body,
.root {
  height: 100%;
  background-color: #171617 !important;
  color: #c7cecc;
}

html {
  box-sizing: border-box;
  background-color: #171617 !important;
  color: #c7cecc;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  line-height: 1.15;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.userInfo {
  cursor: help;
  margin: 10px;
}

.header {
  font-size: 3em;
  margin-bottom: 0.5em;
}

.footer {
  opacity: 0.2;
  -webkit-transition: opacity 1.5s ease-in-out;
  transition: opacity 1.5s ease-in-out;
}
.footer:hover {
  opacity: 0.8;
}

/* Layout */

/* .container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.names {
  grid-column: span 6;
}
@media screen and (max-width: 768px) {
  .names {
    grid-column: span 6;
  }
}
@media screen and (max-width: 480px) {
  .names {
    grid-column: span 12;
  }
} */

/* END layout */

/* body {
  background-image: url(http://www.pngmart.com/files/8/Pineapple-PNG-Transparent-Photo.png),
    url(http://pluspng.com/img-png/cat-png-gray-cat-png-image-347.png),
    url(http://pngimg.com/upload/small/grass_PNG401.png);
  background-position: center;
  background-size: cover;
  background-repeat: repeat, repeat, repeat-x;
  background-color: #92ddff;
} */

.img-canvas {
  margin: 1em;
  opacity: 0.2;
  -webkit-filter: grayscale(20%);
          filter: grayscale(20%);
  overflow: hidden;
  position: relative;
  mix-blend-mode: normal;
  -webkit-transition: opacity 1.5s ease-in-out;
  transition: opacity 1.5s ease-in-out;
}

.img-canvas:after {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background: -webkit-linear-gradient(85deg, #171617, transparent);
  background: linear-gradient(5deg, #171617, transparent);
  background-size: 200% 200%;
}

img {
  width: 150px;
  height: auto;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

a {
  color: #c7cecc !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* App.css */

.App {
  text-align: center;
}

.wrapper {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

footer {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.avatar {
  width: 45px;
  -webkit-filter: grayscale(20%);
          filter: grayscale(20%);
}

/* Background animation */

@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0% 100%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

